body {
  margin: 0;
}
@font-face {
  font-family: "Nasalization";
  src: local("Nasalization"),
   url("./fonts/nasalization/nasalization-rg.ttf") format("truetype");
  font-weight: 400;
 }
 @font-face {
  font-family: "Greycliff CF";
  src: local("Greycliff CF"),
   url("./fonts/greycliff/GreycliffCF-Regular.ttf") format("truetype");
   font-style: normal;
   font-weight: 400;
 }

 @font-face {
  font-family: "Greycliff CF";
  src: local("Greycliff CF"),
   url("./fonts/greycliff/GreycliffCF-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700 !important;
 }
 @font-face {
  font-family: "Greycliff CF";
  src: local("Greycliff CF"),
   url("./fonts/greycliff/GreycliffCF-DemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600 !important;
 }
 @font-face {
  font-family: "Greycliff CF";
  src: local("Greycliff CF"),
   url("./fonts/greycliff/GreycliffCF-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500 !important;
 }